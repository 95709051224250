var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"top_logo"},[(_vm.locale == 'en')?_c('img',{staticClass:"download_h5",attrs:{"src":require("./assets/img/top_app_download_en.png")},on:{"click":function($event){return _vm.goDownload()}}}):_vm._e(),_vm._v(" "),(_vm.locale == 'zh-TW')?_c('img',{staticClass:"download_h5",attrs:{"src":require("./assets/img/top_app_download_tw.png")},on:{"click":function($event){return _vm.goDownload()}}}):_vm._e(),_vm._v(" "),(_vm.locale == 'zh-CN')?_c('img',{staticClass:"download_h5",attrs:{"src":require("./assets/img/top_app_download.png")},on:{"click":function($event){return _vm.goDownload()}}}):_vm._e(),_vm._v(" "),(_vm.locale == 'ko')?_c('img',{staticClass:"download_h5",attrs:{"src":require("./assets/img/top_app_download_en.png")},on:{"click":function($event){return _vm.goDownload()}}}):_vm._e()]),_vm._v(" "),_c('div',{class:{
        title_text: _vm.locale == 'zh-CN' || 'zh-TW',
        title_text_en: _vm.locale == 'en'
      }},[_c('div',{staticClass:"lowlight"})]),_vm._v(" "),_c('div',{staticClass:"subtitle_text",class:{
        subtitle_text: _vm.locale == 'zh-CN' || 'zh-TW',
        subtitle_text_en: _vm.locale == 'en'
      }},[_c('br')]),_vm._v(" "),_c('div',{staticClass:"website_copy"},_vm._l((_vm.domainCanUseTable),function(item,index){return _c('div',{directives:[{name:"ui-test",rawName:"v-ui-test",value:('website-item'),expression:"'website-item'"}],key:index,staticClass:"block"},[_c('div',{staticClass:"block_title"},[_c('img',{staticClass:"web_icon",attrs:{"src":require("./assets/img/web_icon.png")}}),_vm._v(" "),_c('div',{staticClass:"web_title",class:{
              web_title: _vm.locale == 'zh-CN' || 'zh-TW',
              web_title_en: _vm.locale == 'en'
            }},[_vm._v("\n            "+_vm._s(_vm.$t('navigation.mainisland_web'))+_vm._s(index + 1)+"\n          ")])]),_vm._v(" "),_c('div',{staticClass:"block_content"},[_c('div',{staticClass:"web_site",on:{"click":function($event){return _vm.openLink(index)}}},[_vm._v("\n            "+_vm._s(_vm.domainDisplayTable[index])+"\n          ")]),_vm._v(" "),_c('button',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.domainCanUseTable[index]),expression:"domainCanUseTable[index]",arg:"copy"}],class:_vm.currentIndex == index ? 'web_site_copy_sucess' : 'web_site_copy',on:{"click":function($event){return _vm.showCopy(index)}}},[_vm._v("\n            "+_vm._s(_vm.currentIndex === index ? _vm.$t('navigation.copy_sucess') : _vm.$t('navigation.copy_web'))+"\n          ")])])])}),0),_vm._v(" "),_c('div',{staticClass:"hint"},[_c('div',{staticClass:"hint_text"},[_c('div',{staticClass:"hint_title"},[_vm._v(_vm._s(_vm.$t('navigation.pc_hint_title')))]),_vm._v(" "),_c('div',{staticClass:"hint_content1"},[_vm._v("\n          "+_vm._s(_vm.$t('navigation.pc_hint_content1'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"hint_content2"},[_vm._v("\n          "+_vm._s(_vm.$t('navigation.pc_hint_content2'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"hint_content3"},[_vm._v("\n          "+_vm._s(_vm.$t('navigation.pc_hint_content3'))+"\n        ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }