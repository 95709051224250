<template>
  <div>
    <div class="container">
      <div class="top_logo">
        <!-- <img class="logo_h5" src="" /> -->
        <!-- <img class="logo_h5" src="~/assets/img/top_logo2.png" @click="gotoWeex()" /> -->
        <img
          v-if="locale == 'en'"
          class="download_h5"
          src="./assets/img/top_app_download_en.png"
          @click="goDownload()"
        />
        <img
          v-if="locale == 'zh-TW'"
          class="download_h5"
          src="./assets/img/top_app_download_tw.png"
          @click="goDownload()"
        />
        <img
          v-if="locale == 'zh-CN'"
          class="download_h5"
          src="./assets/img/top_app_download.png"
          @click="goDownload()"
        />
        <img
          v-if="locale == 'ko'"
          class="download_h5"
          src="./assets/img/top_app_download_en.png"
          @click="goDownload()"
        />
      </div>
      <div
        :class="{
          title_text: locale == 'zh-CN' || 'zh-TW',
          title_text_en: locale == 'en'
        }"
      >
        <!-- {{ $t("navigation.slogan1") }} -->
        <div class="lowlight">
          <!-- {{ $t("navigation.slogan2") }} -->
        </div>
        <!-- {{ $t("navigation.slogan3") }} -->
      </div>
      <div
        class="subtitle_text"
        :class="{
          subtitle_text: locale == 'zh-CN' || 'zh-TW',
          subtitle_text_en: locale == 'en'
        }"
      >
        <!-- {{ $t("navigation.subtitle_text1") }} -->
        <br />
        <!-- {{ $t("navigation.subtitle_text2") }} -->
      </div>
      <!-- 域名複製區域 -->
      <div class="website_copy">
        <div class="block" v-for="(item, index) in domainCanUseTable" :key="index" v-ui-test="'website-item'">
          <!-- 域名複製區域功能 -->
          <div class="block_title">
            <img class="web_icon" src="./assets/img/web_icon.png" />
            <div
              class="web_title"
              :class="{
                web_title: locale == 'zh-CN' || 'zh-TW',
                web_title_en: locale == 'en'
              }"
            >
              {{ $t('navigation.mainisland_web') }}{{ index + 1 }}
            </div>
          </div>
          <div class="block_content">
            <div class="web_site" @click="openLink(index)">
              {{ domainDisplayTable[index] }}
            </div>
            <button
              v-clipboard:copy="domainCanUseTable[index]"
              @click="showCopy(index)"
              :class="currentIndex == index ? 'web_site_copy_sucess' : 'web_site_copy'"
            >
              {{ currentIndex === index ? $t('navigation.copy_sucess') : $t('navigation.copy_web') }}
            </button>
          </div>
        </div>
      </div>

      <div class="hint">
        <div class="hint_text">
          <div class="hint_title">{{ $t('navigation.pc_hint_title') }}</div>
          <div class="hint_content1">
            {{ $t('navigation.pc_hint_content1') }}
          </div>
          <div class="hint_content2">
            {{ $t('navigation.pc_hint_content2') }}
          </div>
          <div class="hint_content3">
            {{ $t('navigation.pc_hint_content3') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import allPageLoading from './components/allPageLoading.vue';
import { hideGlobalLoading } from '~/utils/utils.js';
import { getOrientedUrls, EnvironmentCheck, getLanguageTypeByLocale } from './utils/common.js';

export default {
	name: 'Support-Index',
  components: {
    allPageLoading
  },
  data() {
    return {
      achorClose: false,
      domainCanUseTable: [],
      domainDisplayTable: [],
      checkDomainRes: 0, //0:before check，１:我們的主域名，2:fake domain，3:fake domain or mutiple domain
      testSpeed: [],
      timeoutID: null,
      timeCount: 10,
      checking: false,
      timeSpeedId: null,
      hideLoading: false,
      locale: '',
      currentIndex: null
    };
  },
  beforeDestroy() {
    clearInterval(this.timeSpeedId);
  },
  watch: {
    // testSpeed(val){
    //   //console.log('watch testSpeed:',this.testSpeed)
    // }
  },
  mounted() {
    this.locale = this.$i18n.locale;
    this.getOrientedUrl();
    hideGlobalLoading();
    //this.getDomainCanUseTable();

    // this.timeSpeedId = setInterval(() => {
    //   this.getSpeed();
    // }, 15000);
  },
  methods: {
    async getOrientedUrl() {
      const app = new EnvironmentCheck();
      //   TODO 环境也需要判断要改？
      app.checkEnvironment();
      const isTest = app.env === 'test';
      const isStaging = app.env === 'staging';
      const aesArr = await getOrientedUrls(app.env);
      console.log('aesArr:', aesArr);
      const _this = this;
      if (isTest || isStaging) {
        aesArr.forEach(function(item, index, array) {
          _this.domainCanUseTable.push(
            'https://' + item + '/' + 'web?languageType=' + getLanguageTypeByLocale(_this.locale)
          );
          _this.domainDisplayTable.push(item.replace('stg-', '').replace('.weex.tech', ''));
        });
      } else {
        aesArr.forEach(function(item, index, array) {
          _this.domainCanUseTable.push(
            'https://' + item + '/' + 'web?languageType=' + getLanguageTypeByLocale(_this.locale)
          );
          _this.domainDisplayTable.push(item.replace('www.', ''));
        });
      }
      console.log('domainCanUseTable:', this.domainCanUseTable);
      console.log('domainDisplayTable:', this.domainDisplayTable);
      this.hideLoading = true;
    },
    gotoWeex(e) {
      const href = this.domainCanUseTable[Math.floor(Math.random() * this.domainCanUseTable.length)];
      window.location.href = href;
    },
    openLink(index) {
      let hrefStr = this.domainCanUseTable[index];
      // window.location.href = hrefStr;
      window.open(hrefStr);
    },
    showCopy(index) {
      //   this.$toast.show(this.$t("navigation.copy_sucess"), {
      //     position: "top-center",
      //     duration: 500,
      //     className: "center_test",
      //   });
      BT.util.success(this.$t('navigation.copy_sucess'));
      this.currentIndex = index;
    },
    goto(link) {
      //console.log("link:", link);
      if (document.body.clientWidth < 768) {
        if (link.indexOf('web') != -1) {
          window.location.href =
            'https://h5' +
            link
              .replace('https://', '')
              .replace('web1', '')
              .replace('web2', '')
              .replace('web3', '')
              .replace('web4', '')
              .replace('web5', ''); //進入官網
        } else {
          if (link.indexOf('www.') != -1) {
            window.location.href = link.replace('www.', 'h5.');
          } else {
            window.location.href = link.replace('https://', 'https://h5.');
          }
        }
      } else {
        window.location.href = link; //進入業務站
      }
    },
    goDownload() {
      var hrefStr = location.origin;
      var href = hrefStr + '/' + this.locale + '/download';
      window.open(href);
    },
    save() {
      if (window.innerWidth >= 768) {
        alert('请使用Ctrl+D进行添加');
      } else {
        if (navigator.share) {
          navigator
            .share({
              title: 'WEEX',
              text: '導航網',
              url: location.origin
            })
            .then(() => console.log('Successful share'))
            .catch(error => console.log('Error sharing', error));
        } else {
          console.error("Browser doesn't support Web Share API");
        }
      }
    },
    closeAnchor() {
      this.achorClose = true;
    },
    getSpeed() {
      let _this = this;
      this.domainCanUseTable.forEach(function(item, index, array) {
        let before_time = new Date().getTime();
        let testUrl = item + '_adminx/?x=' + before_time;
        let speed = axios.get(testUrl).then(res => {
          res.data;
          let res_time = new Date().getTime();
          let diffTime = res_time - before_time;
          _this.domainCanUseTable.forEach(function(item, index, array) {
            if (testUrl.indexOf(item) != -1) {
              //console.log("testUrl:",testUrl+  "    before_time:",before_time + "   res_time:",res_time + "   diffTime:",diffTime)
              _this.$set(_this.testSpeed, index, diffTime);
            }
          });
        });
      });
      this.hideLoading = true;
    }
  }
};
</script>

<style lang="less">
/**for PC use */
@media screen and (min-width: 769px) {
  .getWidth1920(@width) {
    @calcWidth: @width * 100vw / 1920;
    @return: @calcWidth;
  }

  html,
  body {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    overflow-y: auto;
  }

  html {
    .getWidth1920(20); // 这个调用将计算的结果存储在变量中
    font-size: @calcWidth;
  }

  .container {
    text-align: center;
    margin: 0 auto;
    background-image: url(./assets/img/background_web2.png);
    background-size: 100%;
    width: 100vw; //for RWD
    background-repeat: no-repeat;
    background-position: top;

    .top_logo {
      display: flex;
      justify-content: space-between;

      .logo_h5 {
        height: 1.5rem;
        margin-top: 1.8rem;
        margin-left: 8rem;
        cursor: pointer;
      }

      .download_h5 {
        width: 9.35rem;
        height: 4.2rem;
        margin-top: 0.3rem;
        margin-left: 80rem;
        cursor: pointer;
      }
    }

    .title_text {
      display: flex;
      height: 4.75rem;
      line-height: 5.15rem;
      margin-top: 6.75rem;
      margin-left: 18rem;
      font-size: 3.7rem;
      font-weight: 600; //Semi Bold,600
      color: #222222;

      .lowlight {
        font-weight: 400; //Regular,400
        color: #222222;
      }
    }

    //英文標題
    .title_text_en {
      display: flex;
      height: 4.75rem;
      line-height: 5.15rem;
      margin-top: 6.75rem;
      margin-left: 18rem;
      font-size: 3.2rem; //避免擋到圖
      font-weight: 600; //Semi Bold,600
      color: #222222;
      font-family: @medium-font;
    }

    .subtitle_text {
      width: 28rem;
      height: 5.6rem;
      line-height: 2.8rem;
      text-align: left;
      margin-top: 2.3rem;
      margin-left: 18rem;
      font-size: 2rem;
      font-weight: 400;
      color: #222222;
      word-break: break-all;
    }

    //英文次標題
    .subtitle_text_en {
      width: 28rem;
      height: 5.6rem;
      line-height: 2.8rem;
      text-align: left;
      margin-top: 2.3rem;
      margin-left: 18rem;
      font-size: 2rem; //避免擋到圖
      word-wrap: break-word !important;
      font-weight: 400;
      color: #222222;
      word-break: break-word;
    }

    .website_copy {
      display: flex;
      justify-content: space-between;
      width: 90vw; //for RWD
      flex-wrap: wrap;
      font-weight: 600;
      color: #58769c;
      margin: 16.7rem 5rem 0; //調整文字避免遮到圖

      .block {
        margin-right: 3.5rem;
        margin-left: 3.5rem;
        width: 10rem;
        height: 10.25rem;
        background: #ffffff;
        border-radius: 0.8rem;
        box-shadow: 0rem 0.8rem 1.3rem 0.1rem rgba(23, 128, 224, 0.18);

        .block_title {
          display: flex;
          white-space: nowrap;
          justify-content: flex-start;
          width: 10rem;
          height: 4rem;
          border-radius: 0.8rem 0.8rem 0 0;
          background: #e1edfa;

          .web_icon {
            margin-top: 1.4rem;
            margin-left: 1.72vw;
            width: 1.61vw; //for RWD
            height: 1.3vw; //for RWD
          }

          .web_title {
            font-size: 1rem;
            overflow: hidden;
            margin-left: 0.5rem;
            line-height: 4rem;
            font-family: @medium-font;
          }
        }

        .block_content {
          .web_site {
            margin-top: 1rem;
            font-family: @medium-font;
            height: 2.5rem;
            line-height: 2.5rem;
            font-size: 16px;
            font-weight: 600;
            color: #222222;
            cursor: pointer;
          }

          .web_site_copy {
            &:focus {
              color: #fa6400;
            }

            margin-top: 0.5rem;
            color: #d8a300;
            font-size: 1rem;
            font-weight: 400;
            font-family: @medium-font;
            border: 0;
            cursor: pointer;
            background: none;
            outline: none;
            border: none;
          }

          .web_site_copy_sucess {
            margin-top: 0.5rem;
            color: #fa6400;
            font-size: 1rem;
            font-weight: 400;
            border: 0;
            background: none;
            outline: none;
            border: none;
          }
        }
      }
    }

    .hint {
      width: 100%;
      height: 3.85rem;
      background: #e6effa;
      text-align: center;
      margin-top: 4.1rem; //for RWD圖和提示之間的空隙

      .hint_text {
        display: flex;
        justify-content: center;
        height: 3.85rem;
        line-height: 3.85rem;
        color: #3f4c63;
        font-size: 1.2rem;
        margin-top: 1.3rem;
        font-weight: 400;

        .hint_content1 {
          margin-left: 1rem;
        }

        .hint_content2 {
          margin-left: 0.5rem;
        }

        .hint_content3 {
          margin-left: 0.5rem;
        }
      }
    }
  }
}

/**for tablet use */
@media screen and (max-width: 768px) {
  .getWidth750(@width) {
    @calcWidth: @width * 100vw / 750;
    @return: @calcWidth;
  }

  html,
  body {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    overflow-y: auto;
    .getWidth750(20); // 这个调用将计算的结果存储在变量中
    font-size: @calcWidth;
  }

  .container {
    text-align: center;
    margin: 0 auto;
    background-image: url(./assets/img/background.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top;

    .top_logo {
      display: flex;
      justify-content: space-between;

      .logo_h5 {
        width: 10.95rem;
        height: 2.3rem;
        margin-top: 1.95rem;
        margin-left: 1.5rem;
      }

      .download_h5 {
        width: 13.1rem;
        height: 5.5rem;
        position: fixed;
        top: 0.5rem;
        right: 0.5rem;
      }
    }

    .title_text {
      display: flex;
      height: 5.15rem;
      width: 100%;
      line-height: 5.15rem;
      margin-top: 4.85rem;
      margin-left: 1.5rem;
      font-size: 3.7rem;
      font-weight: 600; //Semi Bold,600
      font-family: @medium-font;
      color: #222222;

      .lowlight {
        font-weight: 400; //Regular,400
        color: #222222;
      }
    }

    .subtitle_text {
      height: 5.6rem;
      line-height: 2.8rem;
      text-align: left;
      width: 100%;
      margin-top: 1.5rem;
      margin-left: 1.5rem;
      font-size: 2rem;
      font-weight: 400;
      color: #222222;
      margin-bottom: 42.65rem;
    }

    .website_copy {
      display: flex;
      justify-content: space-between;
      width: 83vw; //for RWD (14.45*2+2.6)/37.7
      flex-wrap: wrap;
      font-weight: 600;
      color: #58769c;
      margin: 4.5rem auto 0;
      flex-grow: 1;

      .block {
        width: 14.45rem; //RWD問題
        height: 15.55rem;
        margin-bottom: 2.6rem;
        background: #ffffff;
        border-radius: 0.8rem;
        box-shadow: 0rem 0.8rem 1.3rem 0.1rem rgba(23, 128, 224, 0.18);

        .block_title {
          display: flex;
          justify-content: space-between;
          width: 14.45rem;
          height: 6rem;
          border-radius: 0.8rem 0.8rem 0 0;
          background: #e1edfa;

          .web_icon {
            margin-top: 2.05rem;
            margin-left: 2.4rem;
            width: 2.35rem;
            height: 2.1em;
            line-height: 2.1rem;
          }

          .web_title {
            margin-top: 1.9rem;
            margin-right: 2.2rem;
            height: 2.1em;
            line-height: 2.1rem;
            font-size: 1.5rem;
            white-space: nowrap;
          }

          //英文版域名標題
          .web_title_en {
            margin-top: 1.9rem;
            margin-left: 0.7rem;
            height: 2.1em;
            line-height: 2.1rem;
            font-size: 1.5rem;
            font-family: @medium-font;
          }
        }

        .block_content {
          .web_site {
            margin-top: 1.35rem;
            height: 2.5rem;
            line-height: 2.5rem;
            font-size: 16px;
            font-weight: 600;
            color: #222222;
            cursor: pointer;
            font-family: @medium-font;
          }

          .web_site_copy {
            margin-top: 1.7rem;
            color: #d8a300;
            font-size: 1.5rem;
            font-weight: 400;
            font-family: @medium-font;
            border: 0;
            cursor: pointer;
            background: none;
            outline: none;
            border: none;
          }

          .web_site_copy_sucess {
            margin-top: 1.7rem;
            color: #fa6400;
            font-size: 1.5rem;
            font-weight: 400;
            font-family: @medium-font;
            border: 0;
            background: none;
            outline: none;
            border: none;
          }
        }
      }
    }

    .hint {
      height: 13.35rem;
      font-size: 1.4rem;
      text-align: center;
      background: #e6effa;
      margin-top: 4.5rem;

      .hint_text {
        line-height: 2.3rem;
        color: #3f4c63;
        font-size: 1.4rem;

        .hint_title {
          font-family: @medium-font;
          padding-top: 2.05rem;
          font-weight: 600;
        }

        .hint_content1 {
          margin-top: 0.1rem;
          font-weight: 400;
        }

        .hint_content2 {
          margin-top: 0.1rem;
          font-weight: 400;
        }

        .hint_content3 {
          margin-top: 0.1rem;
          font-weight: 400;
        }
      }
    }
  }
}

/**for mobile use */
@media screen and (max-width: 570px) {
  .getWidth750(@width) {
    @calcWidth: @width * 100vw / 750;
    @return: @calcWidth;
  }

  html {
    .getWidth750(20); // 这个调用将计算的结果存储在变量中
    font-size: @calcWidth;
  }

  .container {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    background-image: url(./assets/img/h5_background_mobile.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;

    .top_logo {
      display: flex;
      justify-content: space-between;

      .logo_h5 {
        width: 10vw;
        height: 2rem;
        margin-top: 5vw; //for RWD手動調整，對齊下載
        margin-left: 4vw;
      }

      .download_h5 {
        width: 34vw;
        height: 14.7vw;
        margin-top: 0.3rem;
        margin-right: 0.7rem;
      }
    }

    .title_text {
      display: flex;
      height: 13.7vw;
      width: 100%;
      line-height: 13.7vw;
      margin-top: 12.9vw;
      margin-left: 4vw;
      font-size: 9.8vw;
      font-weight: 600; //Semi Bold,600
      color: #222222;

      .lowlight {
        font-weight: 400; //Regular,400
        color: #222222;
      }
    }

    .subtitle_text {
      height: 30.9vw;
      line-height: 7.4vw;
      text-align: left;
      width: 100%;
      margin-top: 4vw;
      margin-left: 4vw;
      font-size: 5.3vw;
      font-weight: 400;
      color: #222222;
      margin-bottom: 114vw;
    }

    .website_copy {
      display: flex;
      justify-content: space-between;
      width: 83vw; //for RWD (14.45*2+2.6)/37.7
      flex-wrap: wrap;
      font-weight: 600;
      color: #58769c;
      margin: 4.5rem auto 0;
      font-family: @medium-font;

      .block {
        width: 38vw; //RWD問題 14.45/37.5
        height: 41.4vw;
        margin-bottom: 2.6rem;
        background: #ffffff;
        border-radius: 0.8rem;
        box-shadow: 0rem 0.8rem 1.3rem 0.1rem rgba(23, 128, 224, 0.18);

        .block_title {
          width: 100%;
          display: flex;
          justify-content: space-between;
          width: 38vw; //RWD問題 14.45/37.5
          height: 16vw;
          border-radius: 0.8rem 0.8rem 0 0;
          background: #e1edfa;

          .web_icon {
            margin-top: 5.4vw;
            margin-left: 6.4vw;
            width: 6.2vw;
            height: 5.6vw;
            line-height: 5.6vw;
          }

          .web_title {
            font-size: 4vw;
            margin-top: 5.1vw;
            margin-left: 1.86vw;
            height: 5.6vw;
            line-height: 5.6vw;
          }
        }

        .block_content {
          .web_site {
            margin-top: 3.6vw;
            font-family: @medium-font;
            height: 6.7vw;
            line-height: 6.7vw;
            font-size: 16px;
            font-weight: 600;
            color: #222222;
          }

          .web_site_copy {
            &:focus {
              color: #fa6400;
            }

            margin-top: 4.5vw;
            color: #d8a300;
            font-size: 1.5rem;
            font-size: 4vw;
            font-weight: 400;
            font-family: @medium-font;
            border: 0;
            background: none;
            outline: none;
            border: none;
          }

          .web_site_copy_sucess {
            // margin-top: 1.7rem;
            margin-top: 4.5vw;
            color: #fa6400;
            font-size: 1.5rem;
            font-size: 4vw;
            font-weight: 400;
            border: 0;
            background: none;
            outline: none;
            border: none;
          }
        }
      }
    }

    .hint {
      width: 100%;
      height: 36vw;

      .hint_text {
        font-size: 4vw;
        line-height: 6.1vw;
        margin-top: 12vw;
        text-align: center;
        color: #3f4c63;
        background: #e6effa;

        .hint_title {
          font-family: @medium-font;
          font-weight: 600;
          padding-top: 6.7vw;
        }

        .hint_content1 {
          font-weight: 400;
          margin-top: 0.3vw;
        }

        .hint_content2 {
          font-weight: 400;
          margin-top: 0.3vw;
        }

        .hint_content3 {
          font-weight: 400;
          margin-top: 0.3vw;
        }
      }
    }
  }
}

// 複製成功提示
.center_test {
  margin: 40vh auto !important;
  background: rgba(0, 0, 0, 0.76) !important;
  border-radius: 0.7rem !important;
  height: 3rem !important;
  font-size: 1.7rem !important;
  font-weight: 400 !important;
  color: #ffffff !important;
  line-height: 3rem !important;
  text-align: center;
}
@media screen and (max-width: 768px) {
  // #launcher,
  .header_mobile,
  .footer_h5 {
    display: none;
  }
  .loader-total1 {
    .wrap {
      margin-left: -18px !important;
    }
  }
}
</style>
